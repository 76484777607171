import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'LIFESTYLE'}
              title={'Goodbye, Things: The New Japanese Minimalism'}
              image={'/books/good_bye_things.jpg'}
              alt={''}
            >
              <div className={styles.content}>
              <br></br>
              <h2>ENG</h2>
              <br></br>
              <p className={styles.excerpt}>

              "Goodbye, Things: The New Japanese Minimalism" is a book by Fumio Sasaki that explores the concept of minimalism and its practical applications in daily life. The author shares his personal experience of adopting a minimalist lifestyle, describing the benefits it has brought to his life and offering practical tips for readers who want to do the same.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Sasaki begins by explaining how his life was transformed after he started reducing the amount of possessions he owned. He describes how he was able to free up space in his home, reduce stress and anxiety, and focus on the things that truly mattered to him. He believes that minimalism is not just about decluttering your physical space, but also about creating a more meaningful life by letting go of unnecessary material possessions and distractions.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              The book is divided into three parts. The first part discusses the philosophy and principles of minimalism. Sasaki explains how minimalism is not just about getting rid of things, but also about cultivating a more mindful and intentional way of living. He provides several examples of how minimalism can help people focus on their passions and interests, as well as create a more peaceful and harmonious home environment.
              </p>
              <br></br>
              <p className={styles.excerpt}>

              The second part of the book provides practical tips for adopting a minimalist lifestyle. Sasaki explains how to declutter your home, organize your possessions, and create a daily routine that prioritizes the things that matter most. He also discusses the benefits of digital minimalism, such as reducing screen time and limiting the number of apps and social media accounts you use.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              The final part of the book focuses on the benefits of minimalism beyond the personal level. Sasaki discusses how minimalism can have a positive impact on the environment, by reducing waste and encouraging people to consume less. He also explains how minimalism can help us connect with others on a deeper level, by fostering a sense of community and sharing.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Overall, "Goodbye, Things: The New Japanese Minimalism" is a thought-provoking book that offers practical advice and inspiration for anyone who wants to live a more intentional and fulfilling life. Sasaki's writing style is clear and concise, and his personal anecdotes and examples make the book engaging and relatable. Whether you're a seasoned minimalist or just starting out on your journey, this book is a must-read for anyone interested in exploring the benefits of a more mindful and intentional way of living.

              </p>
              </div>

              <div className={styles.content}>
              <br></br>
              <h2>LV</h2>
              <br></br>
              <p className={styles.excerpt}>

              "Ardievu, lietas: jaunais japāņu minimālisms" ir Fumio Sasaki grāmata, kas pēta minimālisma jēdzienu un tā praktisko pielietojumu ikdienas dzīvē. Autors dalās savā personīgajā pieredzē par minimālisma dzīvesveidu, aprakstot ieguvumus, ko tas ir devis viņa dzīvē, un piedāvājot praktiskus padomus lasītājiem, kuri vēlas darīt to pašu.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Sasaki sāk, paskaidrojot, kā viņa dzīve mainījās pēc tam, kad viņš sāka samazināt viņam piederošo īpašumu apjomu. Viņš apraksta, kā viņam izdevās atbrīvot vietu savā mājā, mazināt stresu un trauksmi un koncentrēties uz lietām, kas viņam patiešām bija svarīgas. Viņš uzskata, ka minimālisms ir ne tikai fiziskās telpas pārblīvēšana, bet arī jēgpilnākas dzīves radīšana, atbrīvojoties no nevajadzīgām materiālajām mantām un traucēkļiem.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Grāmata ir sadalīta trīs daļās. Pirmajā daļā tiek apspriesta minimālisma filozofija un principi. Sasaki skaidro, kā minimālisms ir ne tikai atbrīvošanās no lietām, bet arī apdomīgāka un apzinātāka dzīvesveida izkopšana. Viņš sniedz vairākus piemērus, kā minimālisms var palīdzēt cilvēkiem koncentrēties uz savām kaislībām un interesēm, kā arī radīt mierīgāku un harmoniskāku mājas vidi.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Grāmatas otrajā daļā sniegti praktiski padomi minimālisma dzīvesveida piekopšanai. Sasaki paskaidro, kā sakārtot savu māju, sakārtot savu īpašumu un izveidot ikdienas rutīnu, kurā prioritāte ir vissvarīgākajām lietām. Viņš arī apspriež digitālā minimālisma priekšrocības, piemēram, ekrāna laika samazināšanu un izmantoto lietotņu un sociālo mediju kontu skaita ierobežošanu.
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Grāmatas pēdējā daļa koncentrējas uz minimālisma priekšrocībām ārpus personīgā līmeņa. Sasaki apspriež, kā minimālisms var pozitīvi ietekmēt vidi, samazinot atkritumu daudzumu un mudinot cilvēkus patērēt mazāk. Viņš arī paskaidro, kā minimālisms var palīdzēt mums sazināties ar citiem dziļākā līmenī, veicinot kopības sajūtu un dalīšanos.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kopumā "Ardievu, lietas: jaunais japāņu minimālisms" ir pārdomas rosinoša grāmata, kas piedāvā praktiskus padomus un iedvesmu ikvienam, kurš vēlas dzīvot apzinātāku un pilnvērtīgāku dzīvi. Sasaki rakstīšanas stils ir skaidrs un kodolīgs, un viņa personīgās anekdotes un piemēri padara grāmatu saistošu un salīdzināmu. Neatkarīgi no tā, vai esat pieredzējis minimālists vai tikai sāciet savu ceļojumu, šī grāmata ir obligāti jāizlasa ikvienam, kurš vēlas izpētīt apdomīgāka un apzinātāka dzīvesveida priekšrocības.

              </p>
              </div>
            </Blog>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
